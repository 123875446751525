import { useState } from "react";
import { Image as IImage } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { navArrow } from "../../icons/svg.js";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Image {
  image?: IImage;
  text?: string;
}

interface Props {
  images?: Image[];
}

const ImageCarousel: React.FC<Props> = ({ images = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const totalItems = images.length;
  const itemsToShow = 3;

  const showNext = () => {
    const nextIndex = (activeIndex + 1) % totalItems;
    if ((document as any).startViewTransition) {
      (document as any).startViewTransition(() => {
        setActiveIndex(nextIndex);
      });
    } else {
      setActiveIndex(nextIndex);
    }
  };

  const showPrev = () => {
    const prevIndex = (activeIndex - 1 + totalItems) % totalItems;
    if ((document as any).startViewTransition) {
      (document as any).startViewTransition(() => {
        setActiveIndex(prevIndex);
      });
    } else {
      setActiveIndex(prevIndex);
    }
  };
  const getDisplayedItems = () => {
    return Array.from({ length: itemsToShow }, (_, i) => {
      const index = (activeIndex + i) % totalItems;
      return images[index];
    });
  };

  const middleIndex = Math.floor(itemsToShow / 2);
  const middleItem = getDisplayedItems()[middleIndex];

  return (
    <div className="image-carousel mw hs vs-xl">
      <div className="content">
        <div className="carousel-container">
          <button
            className="arrow left"
            onClick={showPrev}
            aria-label="Vis forrige bilde"
          >
            <span>{navArrow}</span>
          </button>
          <div className="items-wrapper">
            <div className="items active fade-in">
              {getDisplayedItems().map((image, index) => (
                <div
                  key={index}
                  className={`item ${index === 1 ? "active" : ""}`}
                >
                  <img src={image.image?.url} alt={image.image?.altText} />
                </div>
              ))}
            </div>
          </div>
          <button
            className="arrow right"
            onClick={showNext}
            aria-label="Vis neste bilde"
          >
            {navArrow}
          </button>
        </div>

        {middleItem?.text && (
          <div className="text">
            <RichText html={middleItem.text} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;
